<template>
  <div style="text-align: left">
    <Card style="height:100%">
      <div class="search-con search-con-top">
        <Select v-model="queryinfo.cluster" style="width:200px" placeholder="请选择集群" @on-enter="clusterchange" @on-change="clusterchange">
          <Option v-for="item in configlist" :value="item.name" :key="item.name">{{ item.name }}</Option>
        </Select>
        <Select v-model="queryinfo.namespace" style="width:200px" placeholder="请选择名称空间" @on-enter="namechange" @on-change="namechange">
          <Option v-for="item in namespacelist" :value="item.name" :key="item.name">{{ item.name }}</Option>
        </Select>
        <Input clearable placeholder="输入标签名 搜索" class="search-input" v-model="queryinfo.label" @on-enter="namechange" @on-change="namechange"/>
        <Button @click="namechange" class="search-btn" type="primary">搜索</Button>
      </div>
      <div>
        <Table border ref="selection" :columns="columns" :data="data" ></Table>
      </div>
    </Card>
  </div>
</template>

<script>
import {get} from "@/api/http.js";
import moment from 'moment';
export default {
  name: "Deployment",
  data() {
    return {
      configlist: [],
      queryinfo: {
        cluster: "",
        label: "",
        namespace: "",
      },
      data: [],
      namespacelist: [],
      columns: [
        {type: 'selection', width: 60, align: 'center'},
        {title: '容器名', key: 'name'},
        {title: '镜像', key: 'image'},
        {title: '名称空间', key: 'namespace'},
        {title: 'pod_ip', key: 'pod_ip'},
        {title: 'host_ip', key: 'host_ip'},
        {title: '创建时间', key: 'start_time',
          render: (h,{row}) =>{
            return h('div',this.time_change(row.start_time))
          }
        },
        {
          title: '操 作',
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.showupdatedrawer(params.row)
                  }
                }
              }, '修 改 '),
              h('a', {
                on: {
                  click: () => {
                    this.isdelete(params.row)
                  }
                }
              }, '删 除')
            ]);
          }
        }
      ],
    }
  },
  methods: {
    time_change(time_str) {
      let res = moment(time_str).format('YYYY-MM-DD HH:mm:ss')
      return res
    },

    clusterchange() {
      this.get_namespace(this.queryinfo["cluster"])
      this.namechange ()
    },

    namechange () {
      this.queryinfo.label = this.queryinfo.label.trim()
      this.getdata(this.queryinfo)
    },
    get_config() {
      const url = "/k8s/api/v1/config"
      get(url,)
          .then((resp)=>{
            this.configlist = resp["data"];
          }).catch(e=>{
        return e
      })
    },
    get_namespace(cluster) {
      const url = `/k8s/api/v1/namespaces?cluster=` + cluster
      get(url,)
          .then((resp)=>{
            this.namespacelist = resp["data"];
          }).catch(e=>{
        return e
      })

    },

    getdata(queryinfo) {
      if (queryinfo["cluster"]) {
        const url = "/k8s/api/v1/pods"
        get(url,queryinfo)
            .then((resp)=>{
              if (resp["data"]) {
                this.data = resp["data"];
              } else {
                this.data = []
              }
            }).catch(e=>{
          return e
        })
      }
    },

  },
  created() {
    this.get_config()
    this.getdata(this.queryinfo)
  },
}
</script>

<style lang="less" scoped>
.search-con {
  padding: 10px 0;

  .search {
    &-col {
      display: inline-block;
      width: 200px;
    }

    &-input {
      display: inline-block;
      width: 250px;
      margin-left: 2px;
    }

    &-btn {
      margin-left: 2px;
    }
  }
}

</style>
